// Vars
// ==================================
$white:                 #fff;
$red:                   #BF2026;
$black:                 #000;
$gray-400:              #dadada;
$gray-600:              #8b8b8b;
$gray-900:              #2d2d2d;
$gray-lightest:         #f8f8f8;

$btn-primary-hover-bg-color: #dd353b;
$btn-secondary-hover-bg-color: #1a1a1a;

$font-family-base:     'ITC Franklin Gothic', Helvetica, Arial, sans-serif;
$font-weight-light:     300;
$font-weight-semi-bold: 500;

$font-size-md:          14px;
$font-size-base:        16px;
$font-size-lg:          20px;
$font-size-xl:          24px;
$h1-font-size:          $font-size-base * 2.5;
$h4-font-size:          $font-size-base * 1.25;


$icon-close:            "\e90f";
$icon-checkbox:         "\e90b";
$icon-checkbox-selected:"\e90a";

// Needed mixin
// ==================================

@mixin icon-font($icon, $icon-color: inherit) {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'boilerplate' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    cursor: pointer;
    content: $icon;
    color: $icon-color;
}

// Class extend
// ==================================
.form-check-input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;

    ~ label {
        font-weight: $font-weight-light;
        text-transform: none;
    }

    &[type=checkbox] {

        ~ label {
            &:before {
                @include icon-font($icon-checkbox);
                color: $red;
                font-size: $font-size-md;
                margin-right: 10px;
            }
        }

        &:checked {

            ~ label {
                &:before {
                    @include icon-font($icon-checkbox-selected);
                    color: $red;

                }
            }
        }
    }

    &[type=radio] {

        ~ label {

            &:before {
                width: $font-size-md;
                height: $font-size-md;
                border: 1px solid $red;
                border-radius: 50%;
                margin-right: 10px;
                display: inline-block;
                content: '';
            }
        }

        &:checked {

            ~ label {
                &:before {
                    background-color: $red;

                }
            }
        }
    }
}

.btn {
    text-transform: uppercase;
    font-size: $font-size-md;
    font-weight: $font-weight-semi-bold;
    letter-spacing: .93px;
    background: $red;

    &:disabled,
    &[readonly] {
        cursor: not-allowed;
    }

    &:focus,
    &:active {
        box-shadow: none;
    }
}

.btn-primary {
    background-color: $red;
    border-color: $red;
    &:hover {
        background-color: $btn-primary-hover-bg-color;
        border-color: $btn-primary-hover-bg-color;
    }
}


.btn-secondary {
    background-color: $black;
    border-color: $black;
    &:hover {
        background-color: $btn-secondary-hover-bg-color;
        border-color: $btn-secondary-hover-bg-color;
    }
}


.page-login,
.page-thankyouforregistering,
.page-gigya-reset-password {

    #resetPassword {
        background: $white;
        max-width: 900px;
        margin: 0 auto;
        padding: 20px 83px 40px;
    }
}

.page-gigya-reset-password {

    .main__inner-wrapper {
        min-height: 65vh;
    }

    .gigya-screen *,
    .gigya-label-text {
        font-family: $font-family-base !important;
    }

    h1.gigya-screen-caption {
        @extend h1;
        border-bottom: none;
        margin-bottom: 40px;
        padding: 0;
        width: 370px !important;
        margin-top: 20px;
        font-size: 38px;
    }

    #login-container_content {
        > div {
            width: 370px !important;
        }
    }

    #resetPassword_content {
        > div {
            width: 370px;
            padding: 0;
        }
    }

    .gigya-label-text {
        color: $gray-900 !important;
        font-weight: $font-weight-semi-bold !important;
    }

    .gigya-screen input[type=password] {
        border-radius: 0;
        border-color: $black;

        &:focus {
            border-color: #e8767b;
            box-shadow: none;
        }
    }

    .gigya-input-submit {
        background-color: $red !important;
        border-color: $red !important;
        text-transform: uppercase;
        font-size: $font-size-md !important;
        margin-top: 20px;
        margin-bottom: 50px;
    }

    #resetPassword_social_0_uiContainer {
        width: 100% !important;
    }
}

// Login Page
.page-login {

    #cboxContent {
        height: 180px !important;
    }

    #cboxTitle {
        padding: 25px 30px;

        .headline {
            font-weight: $font-weight-semi-bold;
            font-size: $font-size-lg;
        }
    }

    #cboxClose {
        position: absolute;
        font-size: $font-size-lg;

        span {
            font-family: 'boilerplate';
            &:before {
                content: $icon-close;
            }
        }

    }
}

.gigya-screen *,
.gigya-label-text {
    font-family: $font-family-base !important;
}

.gigya-screen {
    select,
    .gigya-input-text,
    .gigya-input-password {
        height: 40px !important;
        border: 1px solid $gray-900 !important;
        border-radius: 0 !important;
    }

    select {
        padding-left: 10px !important;
        font-size: $font-size-base;
        font-weight: 400;
    }

    .gigya-input-checkbox {
        @extend .form-check-input;
    }

    .gigya-label {
        @extend .form-check-label;
    }
}

h1.gigya-screen-caption {
    border-bottom: none !important;
    font-size: $h1-font-size !important;
    margin: 20px auto 40px !important;
}

.gigya-layout-row {

    .row {
        clear: both;
    }
}

.gigya-composite-control-header,
.gigya-label-text {
    color: $gray-900 !important;
}

h1.gigya-screen-caption {
    font-family: $font-family-base !important;
    font-size: $h1-font-size !important;
    border-bottom: none;
    margin-bottom: 40px;
    padding: 0;
    width: 370px !important;
    margin-top: 20px;
}

.gigya-composite-control-header {
    font-size: $h4-font-size;
    text-transform: capitalize;
    line-height: 24px;
}

.gigya-social-login-container {
    margin-bottom: 20px;
}

.gigya-login-providers-container {
    width: 100% !important;
    tr, td, tbody {
        display: block !important;
    }
}

.with-site-login {
    max-width: 900px;
}

#askBillTo {
    p {
        max-width: 370px;
    }

    input {
        margin: 0 0 5px 10px;
    }
}

.gigya-input-text,
.gigya-input-password {
    margin-bottom: 20px !important;
    font-size: $font-size-base !important;
}

.gigya-composite-control-checkbox {
    padding-left: 0;
    position: relative !important;

    .gigya-input-checkbox {
        left: 18px;
        top: -2px;
    }
    .gigya-label {
        margin-left: 0 !important;
        color: $black;
    }
}

.gigya-layout-cell {
    padding-bottom: 10px;
}


.with-social-login {
    float: none !important;
}

.gigya-forgotPassword {
    font-weight: $font-weight-semi-bold;
    margin-bottom: 10px;
    color: $red !important;
}

.gigya-layout-row .gigya-composite-control.gigya-composite-control-submit .gigya-input-submit {
    @extend .btn;
    @extend .btn-primary;
    margin: 10px auto;
    display: block;
    padding: 0;
    width: 100%;
    max-width: 370px;
}

.gigya-layout-row, .gigya-layout-cell.under-site-login {
    margin-left: 0 !important;
    width: 100% !important;
    a {
        color: $red !important;
        font-weight: $font-weight-semi-bold;
    }
}

.gigya-array-add-btn,
.gigya-array-remove-btn {
    color: $red !important;
}

.gigya-active .gigya-login-provider-row {
    margin-bottom: 10px !important;
}

.gigya-screen-content div.gigya-screen.landscape {
    width: 100% !important;
    padding-top: 0 !important;
}

.gigya-screen.landscape .gigya-layout-row.with-divider {
    flex-direction: column !important;
}

.gigya-layout-cell.with-divider>.gigya-divider-content {
    top: 0 !important;
}

.gigya-layout-cell.with-divider {
    background-image: none !important;
    margin-top: 15px !important;
}

.gigya-login-provider-row,
.gigya-screen.portrait {
    padding: 0 !important;
}

.gigya-screen-dimmer {
    display: none !important
}

.gigya-screen-loader {
    left: 50%;
    transform: translateX(-50%);
}

.gigya-composite-control {
    &.gigya-composite-control-textbox,
    &.gigya-composite-control-password {
        position: relative;
        margin-bottom: 15px;
    }
}

.gigya-input-text,
.gigya-input-password {
    margin-bottom: 0 !important;
}

.gigya-error-msg {
    position: relative !important;
    text-align: left !important;
    padding-top: 0 !important;
}

.gigya-error-display {
    position: relative;

    .gigya-form-error-msg {
        position: relative;
        font-size: $font-size-md !important;
    }
}

.gigya-error-msg {
    position: absolute;
    bottom: 0;
}

.gigya-show-checkmark {
    width: 350px;
    float: left;
}

#RegistrationStandard {
    .gigya-composite-control {
        &.gigya-composite-control-label {
            display: block;
        }
    }


    dl {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
         /* GMS-256 Change: */
      	height: 545px;
        text-align: left;
        margin-bottom: 30px;

        dt,
        dd {
            margin: 0;
            max-width: 32%;
            display: flex;
            position: relative;
        }

        dt {
            line-height: 1;
            margin-top: 20px;
            margin-bottom: 5px;
        }

        dd {
            @extend .gigya-composite-control-checkbox;

            .gigya-input-checkbox {
                margin-right: 10px
            }

            .form-check-label {
                flex: 1;
                text-transform: capitalize;
            }
        }

    }
}

[data-screenset-element-id="gigya-register-screen"] {
    width: 100% !important;

    .gigya-composite-control-subscription-widget {
        position: relative;

        .form-check-label {
            display: inherit;
        }
    }

    .gigya-label {
        margin-left: 0 !important;
    }

    .subscription-name-label {
        @extend .form-check-label;
        float: left;
        margin: 1px 5px 0 0 !important;
        padding-left: 0 !important;
    }
}

[data-screenset-element-id="gigya-login-screen"] {
    width: 370px !important;
}

.gigya-reset-password-form {
    max-width: 370px;

    .gigya-composite-control-label {
        color: $black;
        text-transform: none;
    }
}

.gigya-button-link {
    input {
        background-color: $red !important;
        border-color: $red !important;
        text-transform: uppercase;
        font-size: $font-size-md !important;
    }
}

[data-screenset-element-id="gigya-forgot-password-success-screen"] {
    width: 370px !important;

    label {
        text-transform: none;
    }
}

.gigya-form-error-msg,
.gigya-error-msg-active {
    color: $red !important;
}

#askMotion {

    p {
        margin-bottom: 10px;
    }

    input {
        margin-bottom: 5px;
    }
}

#areYouThisCo {
    p {
        width: 370px;
        display: flex;

        label {
            margin: 0 10px;
        }
    }
}
// address suggest modal
.gigya-screen {
    .modal-dialog {
        margin: 28px auto;
    }

    .modal-header {
        padding: 20px 20px 0 40px;
        margin: unset;

        .modal-title {
            font-size: $font-size-xl;
            margin: 0;
        }

        .close {
            opacity: 1;

            span {
                font-size: 32px;
            }
        }
    }

    .modal-body {
        padding: 20px 40px;
        font-size: $font-size-base;
    }

    .modal-footer {
        padding: 25px 0;
        margin: 0 40px;
        border-top: 1px solid $gray-400;

        .btn-primary,
        .btn-secondary {
            display: block;
            color: $white;
            text-align: center;
            width: 100%;
            padding: 10px;
        }

        .btn-secondary {
            margin-left: 20px;
        }
    }
}

#register-site-login {
    float: none !important;
    width: 100%;
}

[data-screenset-element-id="LongForm"] {
    .row {
        display: flex !important;
        justify-content: space-between;
        margin: 0 -10px;

        .gigya-composite-control {
            padding: 0 10px 5px !important;
        }
    }
}

.gigya-phone-container {
    .column {
        &:first-child {
            width: 66.66666%;
        }
    }

}

.gigya-country-code-select {
    width: 24% !important;
    margin-right: 5px !important;
    margin-bottom: 0 !important;
}

.gigya-subscriber-phone-number-input {
    height: 40px !important;
    border: 1px solid $gray-900 !important;
    border-radius: 0 !important;
    margin-bottom: 0 !important;
    display: inline-block;
    margin-left: 5px !important;
    width: 70% !important;
}

.gigya-composite-control-phone-number-input {
    .gigya-error-msg {
        margin-left: 27%;
    }
}

#gigya-textbox-state {
    color: $gray-600;
}

.subs-widget-container {
    @extend .custom-checkbox;
}

.gigya-composite-control-subscription-widget,
.custom-checkbox {
    position: relative;
    padding-bottom: 10px !important;
}

.gigya-subscription {
    float: left !important;
    width: 25px !important;

    + .form-check-label {
        text-transform: none;
        font-weight: $font-weight-light;
        width: calc(100% - 25px);
    }
}

.subscription-name-label {
    padding-left: 0 !important;
}

#gigya-checkbox-terms {
    + label {
        &:before {
            margin-right: 7px;
        }
    }
}

[data-screenset-element-id="gigya-complete-registration-screen"] {
    width: 100% !important;
    max-width: 734px !important;
}

@media screen and (max-width: 768px) {

    .gigya-login-providers-container {
        top: 0 !important;
        transform: initial !important;

        .gigya-login-provider {
            margin-right: 0 !important;
            margin-bottom: 10px;
        }
    }

    .gigya-screen {

        .modal-footer {

            .btn-primary {
                margin-bottom: 20px;
            }

            .btn-secondary {
                margin-left: 0;
            }
        }
    }
}

@media screen and (max-width: 767px) {

    .page-login,
    .page-gigya-reset-password {
        .gigya-login-providers-list {
            display: flex !important;
            justify-content: center;
            height: auto !important;
        }

        .with-social-login + .with-divider .gigya-composite-control-label {
            margin-top: 50px !important;
        }

        .gigya-login-provider-row {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            align-items: center;
            height: 100% !important;
            justify-content: center;
            width: 100%;
        }

        .gigya-social-login-container,
        [id^="login-container_social_"] {
            width: 100% !important;
        }



        .gigya-login-provider {
            margin-right: 0 !important;
            margin-bottom: 10px;
        }


        .gigya-login-providers-list-container {
            width: 100% !important;
        }

        [data-screenset-element-id="gigya-register-screen"] {
            width: 100% !important;
        }

        .gigya-screen.portrait.mobile {
            padding: 0 !important;
        }

        .gigya-screen.portrait.mobile h2 {
            font-size: $font-size-lg;
            line-height: 24px;
        }

        .gigya-screen.portrait.mobile .gigya-composite-control-social-login {
            width: 100%;
            right: 0;
        }

        #RegistrationStandard {
            dl {
                height: auto;
                dt,
                dd {
                    max-width: 100%;
                }
            }
        }

        #resetPassword {
            padding: 20px 24px 40px;
        }
    }
}

@media screen and (max-width: 640px) {
    .gigya-register-form {
        .gigya-layout-row {
            &:last-child {
                margin-top: 30px;
            }
        }
    }

    #askBillTo {
        p {
            line-height: 40px;
        }

        input {

            &:first-child {
                margin-left: 100px;
            }
        }
    }

    h1.gigya-screen-caption {
        margin: 20px 0 !important;
        width: auto !important;
    }

    #login-container_content {
        > div {
            width: 100% !important;
        }
    }

    [data-screenset-element-id="gigya-forgot-password-success-screen"] {
        width: 100% !important;
    }

    [data-screenset-element-id="LongForm"] {
        .row {
            .column {
                width: 100%;
            }
        }
    }

    #gigya-textbox-state {
        font-size: 13px;
        margin-bottom: 0;
    }

    .gigya-phone-container {
        .column {
            width: 100%;

            &:first-child {
                width: 100%;
            }
        }
    }

    .gigya-subscriber-phone-number-input {
        width: 100% !important; ;
        margin-left: 0 !important;
    }

    .gigya-composite-control-phone-number-input {
        .gigya-error-msg {
            margin-left: 0;
        }
    }

    .gigya-country-code-select {
        width: 100% !important;
        margin-right: 0 !important;
        margin-bottom: 20px !important;
    }

    .page-gigya-reset-password {

        h1.gigya-screen-caption {
            width: auto !important;
        }

        #resetPassword_content {
            > div {
                width: 100% !important;
                margin: 0;
                padding-left: 24px;
                padding-right: 24px;
            }
        }
    }
}

